.floating-forms {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px
}

.tran {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 5px;
}

.btn-filter {
    margin-top: 20px;
    margin-bottom: 20px;
}